<script setup lang="ts"></script>

<template>
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.6125 0.387453C11.096 -0.129132 10.2583 -0.12917 9.7417 0.387453L6 4.12915L2.2583 0.387453C1.74172 -0.129132 0.904075 -0.12917 0.387453 0.387453C-0.12917 0.904075 -0.129132 1.74172 0.387453 2.2583L4.12915 6L0.387453 9.7417C-0.12917 10.2583 -0.129132 11.096 0.387453 11.6125C0.904037 12.1291 1.74168 12.1292 2.2583 11.6125L6 7.87085L9.7417 11.6125C10.2583 12.1291 11.0959 12.1292 11.6125 11.6125C12.1292 11.0959 12.1291 10.2583 11.6125 9.7417L7.87085 6L11.6125 2.2583C12.1292 1.74168 12.1291 0.904037 11.6125 0.387453Z"
            fill="currentColor"
        />
    </svg>
</template>
